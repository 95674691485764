// src/App.tsx

import React from 'react';
import { ThemeProvider } from '@mui/material/styles';
import Container from '@mui/material/Container';
import theme from './theme';
import VideoDownloader from './pages/VideoDownloader';

const App: React.FC = () => {
  return (
    <ThemeProvider theme={theme}>
      <Container maxWidth="sm">
        <VideoDownloader />
      </Container>
    </ThemeProvider>
  );
};

export default App;
