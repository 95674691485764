// src/pages/VideoDownloader.tsx

import React, { useState } from 'react';
import {
  TextField,
  Button,
  Typography,
  Box,
  CircularProgress,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  LinearProgress,
  Snackbar,
  Alert,
} from '@mui/material';
import axios from 'axios';
import { SnackbarCloseReason } from '@mui/material/Snackbar';

// Interfaces for data types
interface VideoFormat {
  format_id: string;
  extension: string;
  resolution?: string;
  fps?: number;
  filesize?: number;
  filesize_approx?: number;
  tbr?: number;
  vcodec?: string;
  acodec?: string;
  ext?: string;
}

interface VideoInfo {
  id: number;
  url: string;
  title: string;
  formats: VideoFormat[];
}

const VideoDownloader: React.FC = () => {
  // Component states
  const [url, setUrl] = useState<string>('');
  const [videoInfo, setVideoInfo] = useState<VideoInfo | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedFormat, setSelectedFormat] = useState<string>('');
  const [downloadStatus, setDownloadStatus] = useState<string>('');
  const [taskId, setTaskId] = useState<string>('');
  const [progress, setProgress] = useState<number>(0);
  const [downloadLink, setDownloadLink] = useState<string>('');
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
  // States for Snackbar
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [snackbarMessage, setSnackbarMessage] = useState<string>('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<
    'success' | 'error' | 'info' | 'warning'
  >('info');

  // Function to open Snackbar
  const showSnackbar = (
    message: string,
    severity: 'success' | 'error' | 'info' | 'warning' = 'info'
  ) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setOpenSnackbar(true);
  };

  // Handler to close Snackbar
  const handleSnackbarClose = (
      event: React.SyntheticEvent<any, Event> | Event,
      reason?: SnackbarCloseReason
    ) => {
      if (reason === 'clickaway') {
        return;
      }
      setOpenSnackbar(false);
    };

  // Handler to close Alert
  const handleAlertClose = (event: React.SyntheticEvent) => {
    setOpenSnackbar(false);
  };

  // Function to fetch video info
  const handleFetchInfo = async () => {
    if (!url) {
      showSnackbar('Пожалуйста, введите ссылку на видео.', 'warning');
      return;
    }

    setLoading(true);
    setVideoInfo(null);
    setDownloadStatus('');
    setDownloadLink('');
    setSelectedFormat('');
    setProgress(0);

    try {
      const response = await axios.post<VideoInfo>(
       `${API_BASE_URL}/video/info`,
        null, // No data in the body
        { params: { url } } // 'url' is sent as a query parameter
      );
      setVideoInfo(response.data);
      showSnackbar('Информация о видео получена успешно!', 'success');
    } catch (error: any) {
      console.error(error);
      showSnackbar(
        'Не удалось получить информацию о видео. Проверьте ссылку.',
        'error'
      );
    } finally {
      setLoading(false);
    }
  };

  // Function to start video download
  const handleDownload = async () => {
  if (!selectedFormat || !videoInfo) {
    showSnackbar('Пожалуйста, выберите формат для скачивания.', 'warning');
    return;
  }

  setLoading(true);
  setDownloadStatus('Начало скачивания...');
  setDownloadLink('');
  setProgress(0);

  try {
    const response = await axios.post(`${API_BASE_URL}/video/download`, {
      url: videoInfo.url,
      format_id: selectedFormat,
    });

    if (response.data.status === 'SUCCESS') {
      // Video is already downloaded
      setDownloadStatus('Скачивание завершено!');
      setDownloadLink(`${API_BASE_URL}/${response.data.result}`);
      showSnackbar('Скачивание завершено!', 'success');
    } else if (response.data.task_id) {
      // Start polling for task status
      setTaskId(response.data.task_id);
      showSnackbar('Скачивание началось!', 'info');

      const interval = setInterval(async () => {
        try {
          const statusResponse = await axios.get(
            `${API_BASE_URL}/task/status/${response.data.task_id}`
          );
          const status = statusResponse.data.status;

          if (status === 'PENDING') {
            setDownloadStatus('Задача в ожидании...');
          } else if (status === 'STARTED' || status === 'PROGRESS') {
            setDownloadStatus('Скачивание...');
            setProgress(statusResponse.data.progress || 0);
          } else if (status === 'SUCCESS') {
            setDownloadStatus('Скачивание завершено!');
            setDownloadLink(`${API_BASE_URL}/${statusResponse.data.result}`);

            showSnackbar('Скачивание завершено!', 'success');
            clearInterval(interval);
          } else if (status === 'FAILURE') {
            setDownloadStatus('Ошибка при скачивании.');
            showSnackbar('Ошибка при скачивании.', 'error');
            clearInterval(interval);
          }
        } catch (error) {
          console.error(error);
          showSnackbar('Ошибка при проверке статуса задачи.', 'error');
          clearInterval(interval);
        }
      }, 2000);
    } else {
      // Handle unexpected response
      showSnackbar('Неизвестный ответ от сервера.', 'error');
    }
  } catch (error) {
    console.error(error);
    showSnackbar('Не удалось начать скачивание.', 'error');
  } finally {
    setLoading(false);
  }
};

  return (
    <Box sx={{ mt: 5 }}>
      {/* Header */}
      <Typography variant="h4" gutterBottom>
        Скачивание Видео
      </Typography>

      {/* URL Input and Check Button */}
      <Box sx={{ display: 'flex', gap: 2, mb: 2 }}>
        <TextField
          label="Вставьте ссылку на видео"
          variant="outlined"
          fullWidth
          value={url}
          onChange={(e) => setUrl(e.target.value)}
        />
        <Button
          variant="contained"
          color="primary"
          onClick={handleFetchInfo}
          disabled={loading}
        >
          {loading ? <CircularProgress size={24} /> : 'Проверить'}
        </Button>
      </Box>

      {/* Video Info and Format Selection */}
      {videoInfo && (
        <Box sx={{ mb: 2 }}>
          <Typography variant="h6">{videoInfo.title}</Typography>
          <FormControl fullWidth sx={{ mt: 2 }}>
            <InputLabel id="format-select-label">Выберите формат</InputLabel>
            <Select
              labelId="format-select-label"
              value={selectedFormat}
              label="Выберите формат"
              onChange={(e) => setSelectedFormat(e.target.value)}
            >
              {videoInfo.formats.map((format) => (
                <MenuItem key={format.format_id} value={format.format_id}>
                  {format.extension.toUpperCase()} -{' '}
                  {format.resolution || 'N/A'} - {format.vcodec || 'N/A'} /{' '}
                  {format.acodec || 'N/A'}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Button
            variant="contained"
            color="secondary"
            sx={{ mt: 2 }}
            onClick={handleDownload}
            disabled={loading || !selectedFormat}
          >
            {loading ? <CircularProgress size={24} /> : 'Скачать'}
          </Button>
        </Box>
      )}

      {/* Download Status and Progress */}
       {downloadStatus && (
        <Box sx={{ mt: 2 }}>
          <Typography variant="body1">{downloadStatus}</Typography>
          {downloadStatus === 'Скачивание...' && (
            <LinearProgress variant="determinate" value={progress} />
          )}
          {downloadLink && (
            <Button
              variant="outlined"
              component="a" // Преобразуем Button в ссылку
              href={downloadLink}
              download // Добавляем атрибут download
              sx={{ mt: 1 }}
              target="_blank"
              rel="noopener noreferrer"
            >
              Скачать файл
            </Button>
          )}
        </Box>
      )}

      {/* Snackbar for Notifications */}
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={(e,r)=>handleSnackbarClose(e,r)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          onClose={handleAlertClose}
          severity={snackbarSeverity}
          sx={{ width: '100%' }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default VideoDownloader;
